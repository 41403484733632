import React from 'react';

class Preloader extends React.Component {

    render() {
        return <div className="preloader flex-column justify-content-center align-items-center">
            <img className="animation__wobble" src="img/sygnet-black-transparent.png" alt="Assistant Logo" height="90" width="90"/>
        </div>;
    }
}

export default Preloader;
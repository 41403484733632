import React from 'react';
import {EXPLANATIONS} from "./explanations";

export const ReportExplanationsHeader = () => {

    return (
        <tr>
            <th>Shop Name</th>
            {EXPLANATIONS.map(explanation => {
                return <th key={explanation.id}>{explanation.title}</th>
            })}
        </tr>
    )
}
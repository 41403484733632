const CommandVisualDefinitions = {
    "TO_BE_EXECUTED": {
        "title": "To be executed",
        "iconClass": "far fa-clock",
        "color": "#ffffff",
        "cardStyle": "card-primary"
    },
    "IN_PROGRESS": {
        "title": "In progress",
        "iconClass": "fas fa-spinner",
        "color": "#18a2b8",
        "cardStyle": "card-info"
    },
    "EXECUTED": {
        "title": "Executed",
        "iconClass": "far fa-check-circle",
        "color": "#2ac200",
        "cardStyle": "card-success"
    },
    "ABORTED": {
        "title": "Aborted",
        "iconClass": "fas fa-ban",
        "color": "#ffdd00",
        "cardStyle": "card-warning"
    },
    "NOT_SUPPORTED": {
        "title": "Command not supported",
        "iconClass": "far fa-do-not-enter",
        "color": "#ffdd00",
        "cardStyle": "card-warning"
    },
    "ERROR": {
        "title": "Error",
        "iconClass": "fa fa-exclamation-circle",
        "color": "#ff0000",
        "cardStyle": "card-danger"
    }
};

export default CommandVisualDefinitions;
import React from 'react';
import UserInfo from "./UserInfo";
import Brand from "./Brand";
import Menu from "./Menu";

class Side extends React.Component {

    render() {
        return <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Brand />
            <div className="sidebar">
                <UserInfo />
                <Menu />
            </div>
        </aside>;
    }
}

export default Side;
import React from 'react';
import ContentHeader from "../../ContentHeader";
import {ReportUsageHeader} from "./ReportUsageHeader";
import {ReportUsageRow} from "./ReportUsageRow";
import Config from "../../../config/config";
import {Auth} from "@aws-amplify/auth";

class ReportUsageView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {user: {}, data: {}};
    }

    componentDidMount() {
        this.fetchReport();
    }

    fetchReport() {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/reports/usage/'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => this.setState({data: data}))
        });
    }

    render() {
        return <div>
            <ContentHeader title={"Assistant Usage (last " + this.state.data.numberOfAnalyzedDays + " days)"}/>
            <div className="container-fluid">
                <section className="content">
                    {this.state.data.startDate !== undefined ?
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive text-center">
                                            <table className="table text-nowrap">
                                                <thead>
                                                <ReportUsageHeader startDate={this.state.data.startDate}
                                                                   endDate={this.state.data.endDate}/>
                                                </thead>
                                                <tbody>
                                                {this.state.data.shops.map((shop) => {
                                                    return <ReportUsageRow shopName={shop.shopName}
                                                                           data={shop.dayToMomentOfSummary}
                                                                           percentageOfSentSummaries={shop.percentageOfSentSummaries}
                                                                           averageDelayOfSendingSummaryInDays={shop.averageDelayOfSendingSummaryInDays}
                                                                           startDate={this.state.data.startDate}
                                                                           endDate={this.state.data.endDate}/>
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ''}
                </section>
            </div>
        </div>
    }
}

export default ReportUsageView;
import React from 'react';

class DeviceOnlineStatus extends React.Component {
    getOnlineStatus() {
        if (this.props.isOnline === true) {
            return "online";
        } else if (this.props.isOnline === false) {
            return "offline";
        } else {
            return "unknown";
        }
    }

    render() {
        const statusMapping = {
            "online": <span className="bg-success p-2" title="Device is up"><i className="fas fa-thumbs-up"></i></span>,
            "offline": <span className="bg-danger p-2" title="Device is down"><i className="fas fa-thumbs-down"></i></span>,
            "unknown": <span className="bg-secondary p-2" title="Device is in unknown state"><i className="fa fa-fw fa-question"></i></span>
        }

        return statusMapping[this.getOnlineStatus()];
    }
}

export default DeviceOnlineStatus;
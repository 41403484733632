class Utils {
    static getDeviceName(deviceConfiguration) {
        if (deviceConfiguration == null) {
            return "";
        }

        if (deviceConfiguration === "null") {
            return "";
        }

        return JSON.parse(deviceConfiguration).shopName;
    }
}


export default Utils;
import React, {useState} from 'react';
import Dates from "../../utils/dates";
import {SingleToggle} from "./SingleToggle";
import {NavLink} from "react-router-dom";

export const Toggles = (props) => {
    const lastUpdatedAsDate = Dates.formatTimestampInMillisAsPolishDate(props.lastUpdated);

    const [toggleChangeRequested, setToggleChangeRequested] = useState(undefined);

    const onSuccessChange = (name, newValue) => {
        setToggleChangeRequested({"name": name, "newValue": newValue});
    };

    return (
        <div>
            <div className="container-fluid">
                {toggleChangeRequested ?
                    <div>
                        <div className="alert alert-info alert-dismissible">
                            <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                            We have sent a command for a device to change its toggle name
                            <strong> {toggleChangeRequested.name}</strong> value to
                            <strong> {toggleChangeRequested.newValue}</strong>.

                            Click <NavLink to={"/commands/devices/" + props.deviceId}>here</NavLink> if you'd like to monitor the
                            command execution.
                        </div>
                    </div>
                    : ''}

                <div className="card">
                    <div className="card-header">
                        <section className="content text-left">
                            <h4>Updated at {lastUpdatedAsDate.date} {lastUpdatedAsDate.time}</h4>
                        </section>
                    </div>
                    <div className="card-body">
                        <div className="dataTables_wrapper dt-bootstrap4">
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered table-striped dataTable dtr-inline">
                                        <thead>
                                        <tr>
                                            <th className="sorting sorting_asc" tabIndex="0" aria-controls="example1"
                                                rowSpan="1" colSpan="1" aria-sort="ascending"
                                                aria-label="Name">Name
                                            </th>
                                            <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1"
                                                colSpan="1"
                                                aria-label="Value">Value
                                            </th>
                                            <th className="sorting" tabIndex="0" aria-controls="example1" rowSpan="1"
                                                colSpan="1">
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {props.toggles.map((toggle, index) => {
                                            return <SingleToggle
                                                toggle={toggle}
                                                deviceId={props.deviceId}
                                                index={index}
                                                key={index}
                                                onSuccessChange={onSuccessChange}/>
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
        ;
}
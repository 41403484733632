import React from 'react';
import moment from "moment/moment";

class DeviceBackups extends React.Component {

    render() {
        let dateInHumanReadableFormat = moment(this.props.latest.dateOfCreation).format("YYYY-MM-DD HH:mm");

        return <div><strong>{dateInHumanReadableFormat}</strong> <br /> {this.props.latest.filename}</div>
    }
}

export default DeviceBackups;
import React from 'react';

class ContentHeader extends React.Component {

    render() {
        return <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">{this.props.title}</h1>
                    </div>
                    <div className="col-sm-6">
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default ContentHeader;
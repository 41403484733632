import React from 'react';
import {Auth} from "@aws-amplify/auth";
import Config from "../../config/config";

function AbortCommand(props) {
    const command = props.command;

    const abortCommand = () => {
        const payload = {
            "deviceId": command.deviceId,
            "existingStatus": "TO_BE_EXECUTED",
            "newStatus": "ABORTED",
            "commandOutcome": "{}",
            "createdAt": command.createdAt
        };

        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/commands/' + command.id), {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(payload),
            })
                .then((data) => {
                    if (!data.ok) {
                        throw new Error('Something went wrong');
                    }

                    props.onSuccess();
                });
        })

    }

    return (<button type="button" className="btn btn-block bg-gradient-danger btn-flat" onClick={() => abortCommand()}>Abort</button>)
}



export default AbortCommand;
import React from 'react';

export const VersionsHeader = (props) => {

    return (
        <tr>
            <th>Device Name</th>
            <th>Device Id</th>
            <th>Last Update</th>
            {props.containerNames.map((name) => {
                return <th>
                    {name}
                </th>
            })}
        </tr>
    )
}
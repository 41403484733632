import React from 'react';
import moment from "moment";

const JOB_SUFFIX_TO_REMOVE = "JobDetail";
const DATE_FORMAT = "YYYY-MM-DD HH:mm";

class DeviceScheduledJobs extends React.Component {
    isValidInfo() {
        try {
            const jobs = JSON.parse(this.props.jobs);

            return jobs !== undefined;
        } catch (ex) {
            return false;
        }
    }

    renderScheduledJobsInfo() {
        const jobs = JSON.parse(this.props.jobs);

        return <div className="row">
            <div className="col-md-6 offset-1">
                <table className="table table-sm">
                    <thead>
                    <tr>
                        <th>Job Name</th>
                        <th>Last invocation</th>
                        <th>Next invocation</th>
                    </tr>
                    </thead>
                    <tbody>
                    {jobs.map((job) => {
                        const jobName = job.jobName.replace(JOB_SUFFIX_TO_REMOVE, '');
                        const lastInvocation = job.lastInvocation === null ? '-' : moment.unix(job.lastInvocation).format(DATE_FORMAT);
                        const nextInvocation = moment.unix(job.nextInvocation).format(DATE_FORMAT);
                        const rowStyle = job.nextInvocationIsInPast === true ? 'bg-danger' : '';

                        return <tr key={jobName} className={rowStyle}>
                            <td>{jobName}</td>
                            <td>{lastInvocation}</td>
                            <td>{nextInvocation}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    }

    render() {
        const defaultValue = <div>Invalid scheduled jobs info</div>;

        return this.isValidInfo() ? this.renderScheduledJobsInfo() : defaultValue;
    }
}

export default DeviceScheduledJobs;
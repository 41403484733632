import React, {useEffect, useState} from 'react';
import ContentHeader from "../ContentHeader";
import {useParams} from "react-router-dom";
import {Auth} from "@aws-amplify/auth";
import Config from "../../config/config";
import SingleCommand from "./SingleCommand";
import Utils from "../../utils/utils";

function CommandsForDevice() {

    let {deviceId} = useParams();

    const [deviceHeartbeat, setDeviceHeartbeat] = useState({});
    const [commands, setCommands] = useState([]);

    useEffect(() => {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/statuses/' + deviceId), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => setDeviceHeartbeat(data))
        });
    }, [deviceId]);

    useEffect(() => {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/commands?deviceId=' + deviceId), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => setCommands(data.commands))
        });
    }, [deviceId]);

    return (
        <div>
            <ContentHeader title={"Last 10 commands for " + Utils.getDeviceName(deviceHeartbeat.deviceConfiguration)}/>
            <section className="content">
                {commands !== undefined ?
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive text-center">
                                            {
                                                commands.map((command, index) => {
                                                    return <SingleCommand command={command} index={index} key={index}/>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    ''
                }
            </section>
        </div>)
        ;
}

export default CommandsForDevice;
import React from 'react';
import Config from "../../config/config";
import {Auth} from "@aws-amplify/auth";
import Dates from "../../utils/dates";
import EventPayload from "./EventPayload";
import moment from "moment";

class DeviceEvents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {user: {}};
    }

    fetchEventsForDevice(deviceId) {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/events?deviceId=' + deviceId + '&numOfEventsToRead=15'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => this.setState({events: data}))
        });
    }

    render() {
        return <div>
            <button type="button" className="btn btn-block btn-default mb-3" onClick={() => this.fetchEventsForDevice(this.props.deviceId)}>Show last 15 events
            </button>
            {this.state.events !== undefined ?
                <div className="row">
                    <div className="col-md-12">

                        <div className="timeline">
                            {this.state.events.map((event) => {
                                let occurrenceDate = Dates.formatTimestampAsPolishDate(event.occurrenceTime);

                                return <div key={event.deviceId + event.occurrenceTime}>
                                    <i className="far fa-calendar-alt"></i>
                                    <div className="timeline-item">
                                        <span className="time"><i className="fas fa-clock"></i> {occurrenceDate.date} {occurrenceDate.time}</span>
                                        <div className="timeline-body">
                                            <EventPayload payload={event.payload}/>
                                        </div>
                                        <div className="timeline-footer">
                                            {event.versionInfo.commitShort} ({moment(event.versionInfo.buildTime, 'YYYYMMDD-HHmmss').format("YYYY-MM-DD HH:mm")})
                                        </div>
                                    </div>
                                </div>;
                            })}
                            <div>
                                <i className="fas fa-clock bg-gray"></i>
                            </div>
                        </div>
                    </div>
                </div> : ''}
        </div>
    }
}

export default DeviceEvents;
import React from 'react';
import ContentHeader from "../ContentHeader";
import Config from "../../config/config";
import {Auth} from "@aws-amplify/auth";
import VersionStamperRepositorySelectorForm from "./VersionStamperRepositorySelectorForm";

class VersionStamperView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {user: {}, data: []};
    }

    componentDidMount() {
        this.fetchRepositories();
    }

    fetchRepositories() {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/version-stamper/repositories'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => this.setState({data: data}))
        });
    }

    render() {
        return <div>
            <ContentHeader title="Assistant Version Stamper"/>
            <div className="container-fluid">
                <section className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive text-center">
                                        <VersionStamperRepositorySelectorForm repositories={this.state.data}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    }
}

export default VersionStamperView;
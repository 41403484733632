import React from 'react';
import ContentHeader from "../ContentHeader";
import SingleDeviceStatus from "./SingleDeviceStatus";
import Config from "../../config/config";
import {Auth} from "@aws-amplify/auth";
import Utils from "../../utils/utils";

const refreshStatusAfterSoManySeconds = 60;

class DevicesStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {user: {}};
    }

    componentDidMount() {
        this.fetchDeviceHeartbeatStatuses();
        this.fetchPhysicalDevicesState();

        this.interval = setInterval(() => {
            this.fetchDeviceHeartbeatStatuses();
            this.fetchPhysicalDevicesState();
        }, refreshStatusAfterSoManySeconds * 1000);
    }

    fetchDeviceHeartbeatStatuses() {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/statuses/'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => this.setState({heartbeats: data}))
        });
    }

    fetchPhysicalDevicesState() {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/physical-devices-state/'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => this.setState({physicalDevicesState: data}))
        });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return <div>
            <ContentHeader title="Devices Status"/>
            <section className="content">

                {this.state.heartbeats !== undefined && this.state.physicalDevicesState !== undefined ?
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                    <span className="info-box-icon bg-success elevation-1"><i
                                        className="fas fa-thumbs-up"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text">Devices Online</span>
                                        <span className="info-box-number"><h3>{this.state.heartbeats.devicesOnline}</h3></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-danger elevation-1"><i
                                        className="fas fa-thumbs-down"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text">Devices Offline</span>
                                        <span
                                            className="info-box-number"><h3>{this.state.heartbeats.devicesOffline}</h3></span>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix hidden-md-up"></div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">

                                <div className="card">
                                    <div className="card-header border-transparent">
                                        <h3 className="card-title">Heartbeats</h3>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                <tr>
                                                    <th>Device</th>
                                                    <th>Type</th>
                                                    <th>App Version</th>
                                                    <th>Events from range</th>
                                                    <th>IP</th>
                                                    <th>Last Backup</th>
                                                    <th>Last Heartbeat</th>
                                                    <th>DB Stats</th>
                                                    <th>Disk Stats</th>
                                                    <th className="text-center">Device status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.heartbeats.statuses.map((deviceStatus) => {
                                                    return <SingleDeviceStatus key={deviceStatus['deviceId']}
                                                                               status={deviceStatus}
                                                                               physicalDeviceState={this.state.physicalDevicesState.find((element) => element.deviceId === deviceStatus['deviceId'])}
                                                                               deviceName={Utils.getDeviceName(deviceStatus.deviceConfiguration)}/>
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
            </section>
        </div>
    }
}

export default DevicesStatus;
import React from 'react';
import DeviceOnlineStatus from "../heartbeat/DeviceOnlineStatus";
import TerminalDeviceHeartbeatRow from "./TerminalDeviceHeartbeatRow";
import Dates from "../../utils/dates";

class TerminalHeartbeat extends React.Component {

    render() {
        const terminalHeartbeat = this.props.terminalHeartbeats;
        let lastHeartbeat = Dates.formatTimestampInMillisAsPolishDate(terminalHeartbeat.lastBackendAppHeartbeat);

        return (<>
                <tr data-widget="expandable-table" aria-expanded={"false"}>
                    <td> {terminalHeartbeat.shopName} </td>
                    <td> {terminalHeartbeat.terminalsOnline} </td>
                    <td> {terminalHeartbeat.terminalsOffline} </td>
                    <td> {lastHeartbeat.date} {lastHeartbeat.time} </td>
                    <td><DeviceOnlineStatus isOnline={terminalHeartbeat.online}/></td>
                </tr>
                <tr className="expandable-body d-none">
                    <td colSpan="6">
                        <table className="table table-striped table-hover">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>IP Address</th>
                                <th>Version</th>
                                <th>Last heartbeat</th>
                                <th>Device status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                terminalHeartbeat.terminalDevicesHeartbeats.map((terminalDeviceHeartbeat) => {
                                    return <TerminalDeviceHeartbeatRow key={terminalDeviceHeartbeat['id']}
                                                                       terminalDeviceHeartbeat={terminalDeviceHeartbeat}/>
                                })
                            }
                            </tbody>
                        </table>
                    </td>
                </tr>
            </>
        )
            ;
    }
}


export default TerminalHeartbeat;
import React, {useEffect, useState} from 'react';
import ContentHeader from "../../ContentHeader";
import {ReportExplanationsHeader} from "./ReportExplanationsHeader";
import {ReportExplanationsRow} from "./ReportExplanationsRow";
import Config from "../../../config/config";
import {Auth} from "@aws-amplify/auth";
import {NavLink, useParams} from "react-router-dom";

export function ReportExplanationsView() {

    const [data, setData] = useState({});

    let {year} = useParams();

    useEffect(() => {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/reports/explanations/' + (year !== undefined ? year : '')), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => setData(data))
        });
    }, [year]);

    return (<div>
        <ContentHeader
            title={"Assistant Explanations Report (last " + data.numberOfAnalyzedDays + " days)"}/>
        <div className="content ml-3"><NavLink to={"/reports/explanations/" + (new Date().getFullYear() - 1)}
                                               className="ssText">Last Year Stats</NavLink></div>
        <div className="container-fluid">
            <section className="content">
                {data.startDate !== undefined ?
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive text-center">
                                        <table className="table text-nowrap">
                                            <thead>
                                            <ReportExplanationsHeader/>
                                            </thead>
                                            <tbody>
                                            {data.shops.map((shop) => {
                                                return <ReportExplanationsRow shopName={shop.shopName}
                                                                              data={shop.explanationsSummary}/>
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
            </section>
        </div>
    </div>);
}
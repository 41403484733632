import React from 'react';
import Dates from "../../utils/dates";

function CommandBatchDefinition(props) {
    const command = props.command;

    const created = Dates.formatTimestampInMillisAsPolishDate(command.createdAt);

    return (<div className="card bg-pink card-primary">
            <div className="card-header bg-pink ">
                <h3 className="card-title"><strong>{command.type}</strong> ({created.date + " " + created.time})
                </h3>
            </div>

            <div className="card-body text-left">
                <p>Batch ID: {command.batchId}</p>

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">
                                    Payload
                                </h3>
                            </div>
                            <div className="card-body">
                                <pre className="text-light">{JSON.stringify(command.payload, null, 4)}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommandBatchDefinition;
import React from 'react';
import moment from "moment/moment";

export const ReportUsageHeader = (props) => {

    let days = [];

    for (let m = moment(props.startDate); m.diff(props.endDate, 'days') <= 0; m.add(1, 'days')) {
        days.push(m.format('YYYY-MM-DD'));
    }

    return (
        <tr>
            <th>Shop Name</th>
            <th>Explained days</th>
            <th>Avg delay in explanations (days)</th>
            {days.map(day => {
               return <th key={day}>{day}</th>
            })}
        </tr>
    )
}
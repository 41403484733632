import React, {useEffect, useState} from 'react';
import ContentHeader from "../ContentHeader";
import {useParams} from "react-router-dom";
import {Auth} from "@aws-amplify/auth";
import Config from "../../config/config";
import SingleCommandDefinitionForBatch from "./CommandBatchDefinition";
import SingleCommandForBatch from "./SingleCommandForBatch";
import Utils from "../../utils/utils";

function CommandsBatches() {

    let {batchId} = useParams();

    const [commands, setCommands] = useState([]);
    const [deviceIdToName, setDeviceIdToName] = useState(new Map());

    useEffect(() => {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/statuses/'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(json => setDeviceIdToName(mapDeviceIdToName(json)))
        });
    }, []);

    useEffect(() => {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/commands?batchId=' + batchId), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => setCommands(data.commands))
        });
    }, [batchId]);

    const mapDeviceIdToName = (restResponse) => {
        const deviceIdToName = new Map();

        for (const device of restResponse.statuses) {
            deviceIdToName.set(device.deviceId, Utils.getDeviceName(device.deviceConfiguration));
        }

        console.log(deviceIdToName);

        return deviceIdToName;
    }

    const getFirstCommandOrEmpty = () => {
        if (commands.length === 0) {
            return {}
        }

        return commands[0];
    }

    const firstCommandOrEmpty = getFirstCommandOrEmpty();

    return (
        <div>
            <ContentHeader title="Batch command summary"/>
            <section className="content">
                <div>
                    <div className="container-fluid">
                        <div className="row mb-12">
                            <div className="col-sm-12">
                                {firstCommandOrEmpty != null ?
                                    <SingleCommandDefinitionForBatch command={firstCommandOrEmpty}/>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive text-center">
                                            {
                                                commands.map((command, index) => {
                                                    return <SingleCommandForBatch
                                                        deviceName={deviceIdToName.get(command.deviceId)}
                                                        command={command}
                                                        index={index}
                                                        key={index}/>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>);
}

export default CommandsBatches;
import React, {useEffect, useState} from 'react';
import ContentHeader from "../ContentHeader";
import {useParams} from "react-router-dom";
import {Auth} from "@aws-amplify/auth";
import Config from "../../config/config";
import NoTogglesInfo from "./NoTogglesInfo";
import {Toggles} from "./Toggles";
import Utils from "../../utils/utils";

function TogglesForDevice() {

    let {deviceId} = useParams();

    const [deviceHeartbeat, setDeviceHeartbeat] = useState({});
    const [togglesResponse, setTogglesResponse] = useState({});

    useEffect(() => {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/statuses/' + deviceId), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => setDeviceHeartbeat(data))
        });
    }, [deviceId]);

    useEffect(() => {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/toggles?deviceId=' + deviceId), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => setTogglesResponse(data))
        });
    }, [deviceId]);

    return (
        <div>
            <ContentHeader title={"Current toggles state for " + Utils.getDeviceName(deviceHeartbeat.deviceConfiguration)}/>
            <section className="content">
                {togglesResponse !== undefined && togglesResponse.toggles !== undefined ?
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive text-center">
                                            {togglesResponse.updateDateTime !== null ?
                                                <Toggles
                                                    deviceId={togglesResponse.deviceId}
                                                    lastUpdated={togglesResponse.updateDateTime}
                                                    toggles={togglesResponse.toggles}/>
                                                : <NoTogglesInfo
                                                    deviceId={togglesResponse.deviceId}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    ''
                }
            </section>
        </div>)
        ;
}

export default TogglesForDevice;
import React from 'react';
import Dates from "../../utils/dates";

class ContainerStatus extends React.Component {
    getIcon(state) {
        if (state === "UP") {
            return <span title={this.props.status} className="text-green"><i className="fas fa-circle"></i></span>;
        } else if (state === "EXITED") {
            return <span title={this.props.status} className="text-red"><i className="far fa-circle"></i></span>;
        } else {
            return <span title={this.props.status} className="text-yellow"><i className="far fa-question-circle"></i></span>;
        }
    }

    render() {
        const date = Dates.formatTimestampAsPolishDate(this.props.dateOfCreation);

        return <div>
            {this.getIcon(this.props.containerState)}<br />
            <span title={'Created at: ' + date.date + " " + date.time}>{this.props.versionInfo}</span>
        </div>
    }
}

export default ContainerStatus;
import React from 'react';
import ContainerStatus from "./ContainerStatus";
import Dates from "../../utils/dates";
import Utils from "../../utils/utils";

export const VersionsRow = (props) => {

    const aliveThresholdInMs = 2700000; // 45 minutes

    const findConcreteContainer = (containerName) => {
        return props.versions.containerVersions
            .find(item => item.name === containerName);
    }

    const getLastUpdate = () => {
        return props.versions !== undefined ? props.versions.lastUpdate : -1;
    }

    const lastUpdate = getLastUpdate() !== -1 ? Dates.formatTimestampAsPolishDate(getLastUpdate()) : '-';
    const isNotAlive = getLastUpdate() === -1 || Date.now() - getLastUpdate() > aliveThresholdInMs;

    return (
        <tr>
            <td className={isNotAlive ? "align-middle text-yellow text-left" : "align-middle text-left"}>{isNotAlive ?
                <i className="fas fa-exclamation-triangle text-yellow mr-3"
                   title="Device is treated as NOT alive; it shows the latest data we have for it"></i> : ''}{Utils.getDeviceName(props.device.deviceConfiguration)}</td>
            <td className={isNotAlive ? "align-middle text-yellow" : "align-middle"}>{props.device.deviceId}</td>
            <td className={isNotAlive ? "align-middle text-yellow" : "align-middle"}>{lastUpdate.date} {lastUpdate.time}</td>

            {props.versions !== undefined ? props.containerNames.map((name) => {
                let container = findConcreteContainer(name);

                let versionInfo = container !== undefined ? container.tag : '-';
                let dateOfCreation = container !== undefined ? container.dateOfCreation : '-';

                let status = container !== undefined ? container.status : '?';
                let containerState = container !== undefined ? container.state : '?';

                return <td><ContainerStatus
                    status={status}
                    containerState={containerState}
                    dateOfCreation={dateOfCreation}
                    versionInfo={versionInfo}/></td>;
            }) : <td colSpan={props.containerNames.length} className="bg-secondary"></td>}
        </tr>
    )
}
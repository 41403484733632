import React from 'react';
import ContentHeader from "../ContentHeader";
import Config from "../../config/config";
import {Auth} from "@aws-amplify/auth";
import {VersionsHeader} from "./VersionsHeader";
import {VersionsRow} from "./VersionsRow";

class VersionsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {user: {}, data: [], devices: {statuses: []}, containerNames: []};
    }

    componentDidMount() {
        this.fetchHeartbeats();
        this.fetchVersions();
    }

    fetchVersions() {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/physical-devices-state/'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => this.setState({data: data, containerNames: this.readAllContainerNames(data)}))
        });
    }

    fetchHeartbeats() {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/statuses/'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => this.setState({devices: data}))
        });
    }

    readAllContainerNames(restResponse) {
        const containerNames = [];

        for (const item of restResponse) {
            for (const container of item['containerVersions']) {
                containerNames.push(container['name']);
            }
        }

        return [...new Set(containerNames)].sort()
    }

    render() {
        return <div>
            <ContentHeader title="Assistant Versions"/>
            <div className="container-fluid">
                <section className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive text-center">
                                        <table className="table text-nowrap">
                                            <thead>
                                            <VersionsHeader containerNames={this.state.containerNames}/>
                                            </thead>
                                            <tbody>
                                            {this.state.devices.statuses.map((device) => {
                                                return <VersionsRow
                                                    key={device.deviceId}
                                                    device={device}
                                                    containerNames={this.state.containerNames}
                                                    versions={this.state.data.find(item => item.deviceId === device.deviceId)}/>
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    }
}

export default VersionsView;
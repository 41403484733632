import React from 'react';

class Brand extends React.Component {

    render() {
        return <a href="/" className="brand-link">
            <img src="/img/sygnet-black-transparent.png" alt="Assistant Logo" className="brand-image img-circle elevation-3" style={{"opacity": ".8"}}/>
            <span className="brand-text font-weight-light">Assistant Cloud</span>
        </a>;
    }
}

export default Brand;
import React from 'react';

class SingleDeviceStatus extends React.Component {

    render() {
        return <React.Fragment>
            <tr data-widget="table">
                <td className={"align-middle"}>{this.props.num}</td>
                <td className={"align-middle"}>{this.props.data.entrepreneur}</td>
                <td className={"align-middle"}>{this.props.data.company}</td>
                <td className={"align-middle"}>{this.props.data.store}</td>
                <td className={"align-middle"}>{this.props.data.device}</td>
                <td className={"align-middle"}>{this.props.data.deviceId}</td>
            </tr>
        </React.Fragment>
    }
}

export default SingleDeviceStatus;
import React from 'react';

class DeviceVersion extends React.Component {
    isValidBuildVersionInfo() {
        try {
            const version = JSON.parse(this.props.version);

            return (version.buildHash !== undefined);
        } catch (ex) {
            return false;
        }
    }

    renderVersionInfo() {
        const version = JSON.parse(this.props.version);

        return <div><strong>{version.buildHash}</strong></div>
    }

    render() {
        return this.isValidBuildVersionInfo() ? this.renderVersionInfo() : 'Invalid build info';
    }
}

export default DeviceVersion;
import React from 'react';

class EventPayload extends React.Component {
    isJsonPayload() {
        try {
            JSON.parse(this.props.payload);

            return true;
        } catch (ex) {
            return false;
        }
    }

    renderJsonPayload() {
        const payload = JSON.parse(this.props.payload);

        return <div><pre className="text-white">{JSON.stringify(payload, null, 4)}</pre></div>
    }

    render() {
        return this.isJsonPayload() ? this.renderJsonPayload() : <div>{this.props.payload}</div>;
    }
}

export default EventPayload;
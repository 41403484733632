import React from 'react';

class DeviceScheduledJobsAlert extends React.Component {
    isValidInfo() {
        try {
            const jobs = JSON.parse(this.props.jobs);

            return jobs !== undefined;
        } catch (ex) {
            return false;
        }
    }

    atLeastOneJobChoked() {
        return JSON.parse(this.props.jobs).some(job => {
            return job['nextInvocationIsInPast'] === true;
        });
    }

    render() {
        return this.isValidInfo() && this.atLeastOneJobChoked() ?
            <span className="bg-gradient-warning p-2 m-2" title="Device is unhealthy"><i className="fa fa-exclamation-triangle"></i></span>
            :
            '';
    }
}

export default DeviceScheduledJobsAlert;
import React from 'react';
import './App.css';
import Side from "./components/side/Side";
import Footer from "./components/Footer";
import Preloader from "./components/Preloader";
import Navbar from "./components/Navbar";
import ContentWrapper from "./components/ContentWrapper";
import {withAuthenticator} from "@aws-amplify/ui-react";
import {BrowserRouter} from "react-router-dom";

class App extends React.Component {

    render() {
        return <div id="wrapper">
            <BrowserRouter>
                <Preloader/>
                <Navbar/>
                <Side/>
                <ContentWrapper/>
                <Footer/>
            </BrowserRouter>
        </div>;
    }
}


export default withAuthenticator(App);
import React from 'react';
import TogglesSetterForm from "./TogglesSetterForm";
import TogglesUtils from "./togglesUtils";

export const SingleToggle = (props) => {

    const onSuccessChange = (name, newValue) => {
        props.onSuccessChange(name, newValue);
    }

    return (
        <tr>
            <td>{props.toggle.name}</td>
            <td>{props.toggle.value}</td>
            <td>
                <button type="button"
                        className="btn ssBackground btn-block"
                        data-toggle="modal"
                        data-target={"#change-toggle-modal-" + TogglesUtils.calculateToggleId(props.toggle)}>
                    <i className="fas fa-wrench"></i> Change Value
                </button>

                <TogglesSetterForm
                    name={props.toggle.name}
                    currentValue={props.toggle.value}
                    deviceId={props.deviceId}
                    onSuccess={onSuccessChange}/>
            </td>
        </tr>
    )
}
import React, {useState} from 'react';
import {NavLink, useParams} from "react-router-dom";
import {Auth} from "@aws-amplify/auth";
import Config from "../../config/config";

function NoTogglesInfo() {

    let {deviceId} = useParams();

    const [askForToggles, setAskForToggles] = useState();

    const submit = () => {
        document.querySelector("#askForTogglesButton").disabled = true;

        let payload = {
            "deviceId": deviceId
        };

        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/toggles/ask'), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(payload),
            })
                .then((data) => {
                    if (data.ok) {
                        return data.json();
                    }

                    throw new Error('Something went wrong');
                })
                .then((data) => processServerResponse(data));
        })
    }

    const processServerResponse = (jsonPayload) => {
        setAskForToggles(jsonPayload);
    };

    return (
        <div className="container-fluid">
            {askForToggles ?
                <div>
                    <div className="alert alert-info alert-dismissible">
                        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                        We have sent a command for a device to publish its current toggles state. Click <NavLink to={"/commands/devices/" + deviceId}>here</NavLink> if you'd like to monitor the command execution.
                    </div>
                </div>
                : ''}
            <div className="card">
                <div className="card-header">
                    <section className="content text-left">
                        <h4>We lack information about toggles config for this device.</h4>
                    </section>
                </div>
                <div className="card-body">
                    <button id="askForTogglesButton"
                            type="button"
                            className="btn btn-block ssBackground" onClick={submit}>
                        Send request for latest toggles from a device
                    </button>
                </div>
            </div>
        </div>);
}

export default NoTogglesInfo;
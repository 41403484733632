import React from 'react';

class DeviceAdditionalStatus extends React.Component {
    render() {
        if (this.props.status === undefined
            || this.props.status === null) {
            return <div></div>
        }

        const status = this.props.status.deviceStatus;

        if (status === undefined
            || status === null) {
            return <div></div>
        }

        const cpuTemp = status.cpuTemperature !== -1 ? status.cpuTemperature.toFixed(2) : undefined;
        const batteryVoltage = status.batteryVoltage !== -1 ? status.batteryVoltage.toFixed(2) : undefined;

        return <div className={"text-right mt-3"}>
            {cpuTemp !== undefined ? <span><i className="fas fa-thermometer-half mr-2"
                                              title={status.cpuTemperature}></i>{cpuTemp} [C]<br/></span> : ''}

            {batteryVoltage !== undefined ? <span><i className="fas fa-battery-full mr-2"
                                                     title={status.batteryVoltage}></i>{batteryVoltage} [V]</span> : ''}
        </div>;
    }
}

export default DeviceAdditionalStatus;
import React from 'react';
import {createRoot} from "react-dom/client";
import './index.css';
import './App.css';
import * as serviceWorker from './utils/serviceWorker';
import App from "./App";
import awsconfig from "./config/awsconfig";
import {Amplify, I18n} from "aws-amplify";
import {Authenticator, Heading, Image, Text, useTheme, View} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';


Amplify.configure(awsconfig);

I18n.putVocabulariesForLanguage('en', {
    'Sign in': 'Log in'
});

const components = {
    Header() {
        const {tokens} = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Image
                    alt="SimpleStep logo"
                    src="img/logo-black-transparent.svg"
                    width={"50%"}
                />
            </View>
        );
    },
    Footer() {
        const {tokens} = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={"azure"}>
                    &copy; {new Date().getFullYear()} All Rights Reserved
                </Text>
            </View>
        );
    },
    SignIn: {
        Header() {
            const {tokens} = useTheme();

            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    textAlign={"center"}
                    level={3}
                >
                    Assistant&trade; Cloud
                </Heading>
            );
        },
        Footer() {
            // Empty for no 'Forget your password' link
        },
    },
}

const root = createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Authenticator hideSignUp initialState="signIn" components={components}>
            {({user}) => (
                <App/>
            )}
        </Authenticator>
    </React.StrictMode>,
);

serviceWorker.unregister();

import React from 'react';
import DevicesStatus from "./heartbeat/DevicesStatus";
import OrganizationView from "./organization/OrganizationView";
import CommandsView from "./commands/CommandsView";
import {Navigate, Route, Routes} from "react-router-dom";
import CommandsForDevice from "./commands/CommandsForDevice";
import CommandsBatches from "./commands/CommandsBatches";
import TogglesView from "./toggles/TogglesView";
import TogglesForDevice from "./toggles/TogglesForDevice";
import ReportUsageView from "./reports/usage/ReportUsageView";
import {ReportExplanationsView} from "./reports/explanation/ReportExplanationsView";
import VersionsView from "./versions/VersionsView";
import VersionStamperView from "./version-stamper/VersionStamperView";
import TerminalHeartbeatsPanel from "./terminal-heartbeat/TerminalHeartbeatsPanel";

class ContentWrapper extends React.Component {

    render() {
        return <div className="content-wrapper">
            <Routes>
                <Route path="heartbeats" element={<DevicesStatus/>}/>
                <Route path="terminal-heartbeats" element={<TerminalHeartbeatsPanel/>}/>
                <Route path="clients" element={<OrganizationView/>}/>
                <Route path="commands">
                    <Route index element={<CommandsView/>}/>
                    <Route path="devices">
                        <Route path=":deviceId" element={<CommandsForDevice/>}/>
                    </Route>
                    <Route path="batches">
                        <Route path=":batchId" element={<CommandsBatches/>}/>
                    </Route>
                </Route>
                <Route path="toggles">
                    <Route index element={<TogglesView/>}/>
                    <Route path="devices">
                        <Route path=":deviceId" element={<TogglesForDevice/>}/>
                    </Route>
                </Route>
                <Route path="reports">
                    <Route path="usage">
                        <Route index element={<ReportUsageView/>}/>
                    </Route>
                    <Route path="explanations">
                        <Route index element={<ReportExplanationsView/>}/>
                        <Route path=":year" element={<ReportExplanationsView/>}/>
                    </Route>
                </Route>
                <Route path="versions">
                    <Route index element={<VersionsView/>}/>
                </Route>
                <Route path="version-stamper">
                    <Route index element={<VersionStamperView/>}/>
                </Route>
                <Route path="*" element={<Navigate to="/heartbeats" replace/>}/>
            </Routes>
        </div>;
    }
}

export default ContentWrapper;
import React from 'react';
import {NavLink} from "react-router-dom";

class Menu extends React.Component {

    render() {
        return <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false">
                <li className="nav-header">DEVICES</li>
                <li className="nav-item">
                    <NavLink to="heartbeats" className="nav-link">
                        <i className="fas fa-heartbeat nav-icon"></i>
                        <p>Heartbeats</p>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="terminal-heartbeats" className="nav-link">
                        <i className="fas fa-barcode nav-icon"></i>
                        <p>Terminal heartbeats</p>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="commands" className="nav-link">
                        <i className="fas fa-terminal nav-icon"></i>
                        <p>Commands</p>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="toggles" className="nav-link">
                        <i className="fas fa-sliders-h nav-icon"></i>
                        <p>Toggles</p>
                    </NavLink>
                </li>
                <li className="nav-header">VERSIONS</li>
                <li className="nav-item">
                    <NavLink to="versions" className="nav-link">
                        <i className="fas fa-code-branch nav-icon"></i>
                        <p>Versions</p>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="version-stamper" className="nav-link">
                        <i className="fas fa-stamp nav-icon"></i>
                        <p>Version Stamper</p>
                    </NavLink>
                </li>
                <li className="nav-header">REPORTS</li>
                <li className="nav-item">
                    <NavLink to="reports/usage" className="nav-link">
                        <i className="fas fa-table nav-icon"></i>
                        <p>Usage Report</p>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="reports/explanations" className="nav-link">
                        <i className="fas fa-lightbulb nav-icon"></i>
                        <p>Explanations Report</p>
                    </NavLink>
                </li>
                <li className="nav-header">CONFIGURATION</li>
                <li className="nav-item">
                    <NavLink to="clients" className="nav-link">
                        <i className="fas fa-male nav-icon"></i>
                        <p>
                            <span>Clients</span>
                        </p>
                    </NavLink>
                </li>
            </ul>
        </nav>;
    }
}

export default Menu;
import React from 'react';
import ContentHeader from "../ContentHeader";
import Config from "../../config/config";
import {Auth} from "@aws-amplify/auth";
import TerminalHeartbeat from "./TerminalHeartbeat";

const refreshStatusAfterSoManySeconds = 60;

class TerminalServiceHeartbeatsPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            terminalsHeartbeats: []
        };
    }

    componentDidMount() {
        this.fetchTerminalHeartbeats();

        this.interval = setInterval(() => {
            this.fetchTerminalHeartbeats();
        }, refreshStatusAfterSoManySeconds * 1000);
    }

    fetchTerminalHeartbeats() {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/statuses/terminals'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => this.setState({terminalsHeartbeats: data.terminalsHeartbeats}))
        });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div>
                <ContentHeader title="Terminal heartbeats"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th>Device</th>
                                                <th>Terminals Online</th>
                                                <th>Terminals Offline</th>
                                                <th>Last heartbeat</th>
                                                <th>Device status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.terminalsHeartbeats.map((terminalHeartbeats) => {
                                                    return <TerminalHeartbeat
                                                        key={terminalHeartbeats['deviceId']}
                                                        terminalHeartbeats={terminalHeartbeats}
                                                    />
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TerminalServiceHeartbeatsPanel;
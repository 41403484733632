import React from 'react';
import DeviceOnlineStatus from "../heartbeat/DeviceOnlineStatus";
import Dates from "../../utils/dates";

class TerminalDeviceHeartbeatRow extends React.Component {

    render() {
        const {terminalDeviceHeartbeat} = this.props;
        let lastHeartbeat = Dates.formatTimestampInMillisAsPolishDate(terminalDeviceHeartbeat.lastHeartbeat);
        return (
            <tr>
                <td> {terminalDeviceHeartbeat.id} </td>
                <td> {terminalDeviceHeartbeat.name} </td>
                <td> {terminalDeviceHeartbeat.ipAddress} </td>
                <td> {terminalDeviceHeartbeat.appVersion} </td>
                <td> {lastHeartbeat.date} {lastHeartbeat.time} </td>
                <td><DeviceOnlineStatus isOnline={terminalDeviceHeartbeat.online}/></td>
            </tr>
        );
    }
}

export default TerminalDeviceHeartbeatRow;
import React from 'react';
import {Link} from "react-router-dom";
import Dates from "../../utils/dates";
import CommandVisualDefinitions from "./CommandStatuses";
import AbortCommand from "./AbortCommand";
import {useNavigate} from 'react-router'

function SingleCommand(props) {
    const navigate = useNavigate();

    const command = props.command;
    const index = props.index;
    const commandVisualDefinition = CommandVisualDefinitions[command.status];

    const executed = Dates.formatTimestampInMillisAsPolishDate(command.executedAt);
    const lastUpdated = Dates.formatTimestampInMillisAsPolishDate(command.lastUpdatedAt);
    const created = Dates.formatTimestampInMillisAsPolishDate(command.createdAt);

    const canAbortCommand = command.status === "TO_BE_EXECUTED";

    const refreshPage = () => {
        navigate(0);
    }

    return (<div className={"card " + commandVisualDefinition.cardStyle}>
            <div className="card-header">
                <div className="row">
                    <div className="col-md-10">
                        <h3 className="card-title">{index + 1}. <strong
                            title={command.id}
                            onClick={() => {
                                navigator.clipboard.writeText(command.id)
                            }}>{command.type}</strong> ({created.date + " " + created.time})
                            <span className="pl-5"><i
                                className={commandVisualDefinition.iconClass}></i> {commandVisualDefinition.title}</span>
                        </h3>
                    </div>
                    <div className="col-md-2">
                        {canAbortCommand ? <AbortCommand command={command} onSuccess={() => refreshPage()}/> : ''}
                    </div>
                </div>
            </div>

            <div className="card-body text-left">
                <p>Batch ID: <Link to={"/commands/batches/" + command.batchId}
                                   className="ssText">{command.batchId}</Link></p>
                {executed.date !== "" ?
                    <p>Executed date: <strong>{executed.date + " " + executed.time}</strong></p> : ''}
                {command.executionDuration !== null ?
                    <p>Duration: <strong>{command.executionDuration}</strong> (ms)</p> : ''}
                <p>Last Updated: <strong>{lastUpdated.date + " " + lastUpdated.time}</strong></p>

                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">
                                    Payload
                                </h3>
                            </div>
                            <div className="card-body">
                                <pre className="text-light">{JSON.stringify(command.payload, null, 4)}</pre>
                            </div>
                        </div>
                    </div>
                    {command.commandOutcome ?
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Outcome
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <pre className="text-light">{JSON.stringify(command.commandOutcome, null, 4)}</pre>
                                </div>
                            </div>
                        </div> : ''}
                </div>
            </div>
        </div>
    )
}

export default SingleCommand;
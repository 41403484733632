import React from 'react';

class Navbar extends React.Component {

    render() {
        return <nav className="main-header navbar navbar-expand navbar-dark">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="/#" role="button"><i className="fas fa-bars"></i></a>
                </li>
            </ul>
        </nav>;
    }
}

export default Navbar;
import moment from "moment/moment";

class Dates {
    static formatTimestampInMillisAsPolishDate(timestampInMilliseconds) {
        if (timestampInMilliseconds === null || timestampInMilliseconds === undefined) {
            return {
                date: "",
                time: ""
            }
        }

        return this.formatTimestampAsPolishDate(timestampInMilliseconds / 1000);
    }

    static formatTimestampAsPolishDate(timestampInSeconds) {
        let formatter = Intl.DateTimeFormat('pl-PL', this.options);

        if (timestampInSeconds === null || timestampInSeconds === undefined) {
            return {
                date: undefined,
                time: undefined
            }
        }

        try {
            let shouldMultiplyToMillis = timestampInSeconds < 1000000000000;
            let multiplier = shouldMultiplyToMillis ? 1000 : 1;

            let format = formatter.format(new Date(timestampInSeconds * multiplier));
            let dateSplitted = format.split(', ');

            return {
                date: dateSplitted[0],
                time: dateSplitted[1]
            }
        } catch (e) {
            return {
                date: undefined,
                time: undefined
            }
        }
    }

    static formatTimestampAsIsoDate(timestampInSeconds) {
        if (timestampInSeconds === null || timestampInSeconds === undefined) {
            return undefined;
        }

        return new Date(timestampInSeconds * 1000).toISOString().split('T')[0];
    }

    static formatIsoDateAsPolishDate(yearMonthDayDate) {
        if (yearMonthDayDate === null || yearMonthDayDate === undefined) {
            return undefined;
        }

        let dateSplitted = yearMonthDayDate.split('-');

        return dateSplitted[2] + '.' + dateSplitted[1] + '.' + dateSplitted[0];
    }

    static getDateFromParamOrPreviousDay(dateFromProps) {
        let date = this.getDateFromParam(dateFromProps);

        if (date != null) {
            return date;
        }

        return moment().subtract(1, 'days').format("YYYY-MM-DD");
    }


    static getDateFromParam(dateFromProps) {
        let date;

        if (dateFromProps !== undefined) {
            date = dateFromProps;
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            date = urlParams.get('date');
        }

        return date;
    }
}

Dates.options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
};


export default Dates;
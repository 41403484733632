import React from 'react';
import {Auth} from "@aws-amplify/auth";
import $ from 'jquery'

class UserInfo extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {user: undefined};
    }

    componentDidMount() {
        Auth.currentUserInfo().then((result) => {
            const user = {"username": result.username, "fullName": result.attributes.given_name + " " + result.attributes.family_name};
            this.setState({user: user});
            $(".preloader").hide(); // Naive fix to deal with preloader NOT hiding when this is a first login
        });
    }

    signOut = () => {
        Auth.signOut()
            .catch(err => console.log(err));
    }

    render() {
        return <div>
            {this.state.user !== undefined ?
                <div className="user-panel mt-3 pb-3 mb-3 d-flex align-items-center">
                    <div className="pull-left image">
                        <img src="/img/myself.jpg" className="img-circle" alt="User Avatar"/>
                    </div>
                    <div className="pull-left info">
                        {this.state.user.fullName}
                        <div style={{"fontSize": "11px"}}>
                            <a href="/" onClick={this.signOut}><i className="fa fa-sign-out-alt"></i> Logout</a>
                        </div>
                    </div>
                </div>
                :
                ''}
        </div>
    }
}

export default UserInfo;
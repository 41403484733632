import React from 'react';
import Dates from "../../utils/dates";
import DeviceOnlineStatus from "./DeviceOnlineStatus";
import DeviceVersion from "./DeviceVersion";
import DeviceScheduledJobs from "./DeviceScheduledJobs";
import DeviceScheduledJobsAlert from "./DeviceScheduledJobsAlert";
import DeviceEvents from "./DeviceEvents";
import DeviceDiskUsage from "./DeviceDiskUsage";
import DeviceDbUsage from "./DeviceDbUsage";
import DeviceDbEvents from "./DeviceDbEvents";
import DeviceBackups from "./DeviceBackups";
import DeviceAdditionalStatus from "./DeviceAdditionalStatus";
import DeviceType from "./DeviceType";

class SingleDeviceStatus extends React.Component {

    readLatestBackup(deviceStatus) {
        try {
            return deviceStatus.latestBackups[0];
        } catch (e) {
            return {};
        }
    }

    render() {
        const deviceStatus = this.props['status'];
        const physicalDeviceState = this.props['physicalDeviceState'];

        const lastHeartbeatUpdate = Dates.formatTimestampAsPolishDate(deviceStatus.lastUpdate / 1000);
        const lastPhysicalDeviceStateUpdate = physicalDeviceState !== undefined ? Dates.formatTimestampAsPolishDate(physicalDeviceState.lastUpdate / 1000) : {
            "date": "-",
            "time": "-"
        };

        const deviceId = deviceStatus.deviceId;

        const ipAddress = physicalDeviceState !== undefined ? physicalDeviceState.network.ipAddress : '-';
        const wireguardIp = physicalDeviceState !== undefined ? physicalDeviceState.network.wireguardIp : '-';

        const mac = physicalDeviceState !== undefined ? physicalDeviceState.network.mac : '-';
        const latestBackup = physicalDeviceState !== undefined ? this.readLatestBackup(physicalDeviceState) : {
            "dateOfCreation": "-",
            "filename": "-"
        };

        const dbEventsStats = JSON.parse(deviceStatus.dbEventsStats);
        const configuration = JSON.parse(deviceStatus.deviceConfiguration);

        const deviceName = configuration !== null ? configuration.shopName : "Unknown name";

        return <React.Fragment>
            <tr data-widget="expandable-table" aria-expanded={"false"}>
                <td className={"align-middle"}>{deviceName}</td>
                <td className={"align-middle"}><DeviceType physicalDeviceState={physicalDeviceState}/></td>
                <td className={"align-middle"}><DeviceVersion version={deviceStatus.appVersion}/></td>
                <td className={"align-middle"}><DeviceDbEvents earliest={dbEventsStats.earliestEventDate}
                                                               latest={dbEventsStats.latestEventDate}/></td>
                <td className={"align-middle"}><span title={mac}>{ipAddress}</span><br/><span title="Wireguard IP Address">{wireguardIp}</span></td>
                <td className={"align-middle"}><DeviceBackups latest={latestBackup}/></td>
                <td className={"align-middle"}><span
                    title="Application last heartbeat">{lastHeartbeatUpdate.date} {lastHeartbeatUpdate.time}</span><br/>
                    <span
                        title="Controller last heartbeat">{lastPhysicalDeviceStateUpdate.date} {lastPhysicalDeviceStateUpdate.time}</span>
                </td>
                <td className={"align-middle"}><DeviceDbUsage usage={JSON.parse(deviceStatus.dbObjectSizes)}/></td>
                <td className={"align-middle"}>
                    {physicalDeviceState !== undefined ? <div>
                            <DeviceDiskUsage usage={physicalDeviceState.diskUsage[0]} desc="Root usage" name="/"/><br/>
                            <DeviceDiskUsage usage={physicalDeviceState.diskUsage[1]} desc="SD Card usage" name="SD"/></div>
                        : ''}
                </td>
                <td className={"text-center align-middle"}>
                    <DeviceScheduledJobsAlert jobs={deviceStatus.scheduledJobsStatus}/>
                    <DeviceOnlineStatus isOnline={deviceStatus.isOnline}/>
                    {physicalDeviceState !== undefined ?
                        <DeviceAdditionalStatus status={physicalDeviceState}/>
                        : ''}
                </td>
            </tr>
            <tr className="expandable-body d-none">
                <td colSpan="7">
                    <div className={"ml-3"}>Device ID: <strong>{deviceStatus.deviceId}</strong></div>
                    <DeviceScheduledJobs key={deviceId} jobs={deviceStatus.scheduledJobsStatus}/>
                    <DeviceEvents deviceId={deviceId}/>
                </td>
            </tr>
        </React.Fragment>
    }
}

export default SingleDeviceStatus;
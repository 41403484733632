import React from 'react';
import {Auth} from "@aws-amplify/auth";
import Config from "../../config/config";
import TogglesUtils from "./togglesUtils";

class TogglesSetterForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formError: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    onCloseModal() {
        this.clearFormValidationFeedbackAndErrors();
        document.querySelector("#close-modal-window-" + TogglesUtils.calculateToggleId(this.props)).click();
    }

    clearFormValidationFeedbackAndErrors() {
        this.setState({formError: false});
        this.form().classList.remove('was-validated');
        this.form().reset();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'select-multiple' ? this.resolveMultipleSelectOptions(target) : target.value;
        const name = target.id;

        this.setState({
            [name]: value
        });
    }

    form() {
        return document.querySelector('#setToggleForm');
    }

    render() {
        return <div className="modal fade" id={"change-toggle-modal-" + TogglesUtils.calculateToggleId(this.props)} style={{display: "none"}} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Change Toggle</h4>
                        <button type="button"
                                id={"close-modal-window-" + TogglesUtils.calculateToggleId(this.props)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.onCloseModal()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <div className="container-fluid">
                                <section className="content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body p-0">
                                                    <div className="table-responsive text-center">
                                                        <form id="setToggleForm">
                                                            <div className="form-group">
                                                                <label htmlFor="commandTypeInput">Name</label>
                                                                <input type="text" className="form-control"
                                                                       placeholder={this.props.name} disabled="true" />
                                                                <div className="form-group">
                                                                    <label>Value</label>
                                                                    <textarea id="toggleValueInput"
                                                                              className="form-control"
                                                                              rows="5"
                                                                              onChange={this.handleInputChange}>{this.props.currentValue}</textarea>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn ssBackground btn-block"
                                onClick={() => this.submit()}>Save
                        </button>
                        {this.state.formError !== false ?
                            <div
                                className="alert alert-danger alert-dismissible w-100">
                                <h5><i className="icon fas fa-ban"></i> Error </h5>
                                {this.state.formError}
                            </div> : ''}
                    </div>
                </div>

            </div>
        </div>
    }

    submit() {
        this.form().classList.add('was-validated');

        let formIsValid = this.form().checkValidity();
        if (!formIsValid) {
            return;
        }

        let payload = {
            "deviceId": this.props.deviceId,
            "name": this.props.name,
            "value": this.state.toggleValueInput
        };

        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/toggles'), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(payload),
            })
                .then((data) => {
                    if (data.ok) {
                        return data.json();
                    }

                    this.onFormSendingError();
                    throw new Error('Something went wrong');
                })
                .then(() => this.processServerResponse());
        })
    }

    onFormSendingError() {
        this.setState({formError: 'Problem during communication with the server.'});
    }

    processServerResponse() {
        this.onCloseModal();
        this.setState({
            formError: false
        });
        this.props.onSuccess(this.props.name, this.state.toggleValueInput);
    }
}

export default TogglesSetterForm;
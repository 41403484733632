import React from 'react';
import moment from "moment/moment";

export const ReportUsageRow = (props) => {

    let days = [];

    for (let m = moment(props.startDate); m.diff(props.endDate, 'days') <= 0; m.add(1, 'days')) {
        days.push(m.format('YYYY-MM-DD'));
    }

    const getBadgeForExplainedDays = (value) => {
        if (value >= 80) {
            return <span className="badge bg-success">{value}%</span>
        } else if (value >= 50) {
            return <span className="badge bg-warning">{value}%</span>
        } else {
            return <span className="badge bg-danger">{value}%</span>
        }
    }

    const getBadgeForDelayInExplanations = (value) => {
        if (value === "0.00") {
            return <span>-</span>
        }

        if (value <= 2) {
            return <span className="badge bg-success">{value}</span>
        } else if (value <= 5) {
            return <span className="badge bg-warning">{value}</span>
        } else {
            return <span className="badge bg-danger">{value}</span>
        }
    }

    const getBadgeForDayExplanation = (value) => {
        if (value === undefined) {
            return <span>-</span>
        }

        if (value <= 2) {
            return <span className="badge bg-success">{value}</span>
        } else if (value <= 5) {
            return <span className="badge bg-warning">{value}</span>
        } else {
            return <span className="badge bg-danger">{value}</span>
        }
    }

    return (
        <tr>
            <td>{props.shopName}</td>
            <td>{getBadgeForExplainedDays(props.percentageOfSentSummaries)}</td>
            <td>{getBadgeForDelayInExplanations(parseFloat(props.averageDelayOfSendingSummaryInDays).toFixed(2))}</td>
            {days.map(day => {
                if (props.data[day] === null) {
                    return <td>-</td>;
                } else {
                    const differenceInMs = new Date(props.data[day]) - new Date(day);
                    const differenceInDays = differenceInMs / 1000 / 60 / 60 / 24;
                    const normalizedDifferenceInDays = differenceInDays - 1;
                    const daysForSendingSummary = parseFloat(normalizedDifferenceInDays).toFixed(2);

                    return <td
                        title={daysForSendingSummary + " days passed for the user to send day summary"}>{getBadgeForDayExplanation(daysForSendingSummary)}</td>
                }
            })}
        </tr>
    )
}
import React, {useEffect, useState} from 'react';
import {EXPLANATIONS} from "./explanations";

export const ReportExplanationsRow = (props) => {
    const [totalNumberOfExplanations, setTotalNumberOfExplanations] = useState(0);

    useEffect(() => {
        let numOfExplanations = 0;

        Object.keys(props.data).map(reason => {
            numOfExplanations += props.data[reason].numberOfOccurrences;
            return numOfExplanations;
        });
        setTotalNumberOfExplanations(numOfExplanations);
    }, [props.data]);

    const getFormattedCell = (occurrencesNum, totalAmountGross) => {
        const amountGrossHumanReadable = (totalAmountGross / 100).toFixed(2).toLocaleString();

        const percentageUsageOfExplanationReason = occurrencesNum / totalNumberOfExplanations * 100;

        if (percentageUsageOfExplanationReason >= 25) {
            return <span className="badge bg-danger">{occurrencesNum}<br/>{amountGrossHumanReadable} zł</span>
        } else if (percentageUsageOfExplanationReason >= 5) {
            return <span className="badge bg-warning">{occurrencesNum}<br/>{amountGrossHumanReadable} zł</span>
        } else {
            return <span className="badge bg-success">{occurrencesNum}<br/>{amountGrossHumanReadable} zł</span>
        }
    }

    return (
        <tr>
            <td>{props.shopName}</td>

            {EXPLANATIONS.map(explanationReason => {
                const singleData = props.data[explanationReason.id];

                if (singleData === null || singleData === undefined) {
                    return <td key={explanationReason.id}>-</td>;
                } else {
                    return <td key={explanationReason.id}>{getFormattedCell(singleData.numberOfOccurrences, singleData.totalAmountGross)}</td>
                }
            })}
        </tr>
    )
}
import React from 'react';
import {Auth} from "@aws-amplify/auth";
import Config from "../../config/config";
import {Navigate} from "react-router-dom"
import Utils from "../../utils/utils";

class CommandSenderForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            heartbeats: {statuses: []},
            supportedCommands: undefined,
            formError: false,
            shouldRedirect: false,
            shouldRedirectToBatchId: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    onCloseModal() {
        this.clearFormValidationFeedbackAndErrors();
        this.clearCommandFields();
        document.querySelector('#closeModalButton').click();
    }

    clearFormValidationFeedbackAndErrors() {
        this.setState({formError: false});
        this.form().classList.remove('was-validated');
        this.form().reset();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'select-multiple' ? this.resolveMultipleSelectOptions(target) : target.value;
        const name = target.id;

        this.setState({
            [name]: value
        });
    }

    resolveMultipleSelectOptions(target) {
        const options = target.options;
        let value = [];

        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].id);
            }
        }

        return value;
    }

    clearCommandFields() {
        this.setState({commandTypeInput: undefined, inputCashType: undefined});
    }

    componentDidMount() {
        this.fetchHeartbeats();
        this.fetchSupportedCommands();
    }

    form() {
        return document.querySelector('#addCommandForm');
    }

    fetchHeartbeats() {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/statuses/'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => this.setState({heartbeats: data}))
        });
    }

    fetchSupportedCommands() {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/commands/supported'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => this.setState({supportedCommands: data.commands}))
        });

    }

    render() {
        return <div>
            {this.state.supportedCommands !== undefined ?
                <div className="modal fade" id="add-command-modal" style={{display: "none"}} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Send Command</h4>
                                <button type="button"
                                        id="closeModalButton"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => this.onCloseModal()}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div className="container-fluid">
                                        <section className="content">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card">
                                                        <div className="card-body p-0">
                                                            <div className="table-responsive text-center">
                                                                <form id="addCommandForm">
                                                                    <div className="form-group">
                                                                        <label htmlFor="commandTypeInput">Type</label>
                                                                        <select
                                                                            className="form-control"
                                                                            id="commandTypeInput"
                                                                            onLoadedData={this.handleInputChange}
                                                                            onChange={this.handleInputChange}>
                                                                            {this.state.supportedCommands.map((command) => {
                                                                                return <option
                                                                                    key={command}>{command}</option>
                                                                            })}
                                                                        </select>
                                                                        <div className="form-group">
                                                                            <label>Payload</label>
                                                                            <textarea id="commandPayloadInput"
                                                                                      className="form-control"
                                                                                      rows="10"
                                                                                      placeholder="JSON"
                                                                                      onChange={this.handleInputChange}></textarea>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label>Devices</label>
                                                                            <select multiple={true}
                                                                                    id="commandDeviceInput"
                                                                                    className="form-control"
                                                                                    style={{height: "280px"}}
                                                                                    required
                                                                                    onChange={this.handleInputChange}>
                                                                                {this.state.heartbeats.statuses.map((device) => {
                                                                                    return <option id={device.deviceId}
                                                                                                   key={device.deviceId}>{device.deviceId} - {Utils.getDeviceName(device.deviceConfiguration)}</option>
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn ssBackground btn-block"
                                        onClick={() => this.submit()}>Send
                                </button>
                                {this.state.formError !== false ?
                                    <div
                                        className="alert alert-danger alert-dismissible w-100">
                                        <h5><i className="icon fas fa-ban"></i> Error </h5>
                                        {this.state.formError}
                                    </div> : ''}
                            </div>
                        </div>

                    </div>
                    {this.state.shouldRedirect &&
                        <Navigate to={"/commands/batches/" + this.state.shouldRedirectToBatchId} replace={true}/>}
                </div>
                : ''}
        </div>
    }

    fieldIsNotEmpty(field) {
        return field !== undefined && field !== "";
    }

    submit() {
        this.form().classList.add('was-validated');

        let formIsValid = this.form().checkValidity();
        if (!formIsValid) {
            return;
        }

        const commandType = this.fieldIsNotEmpty(this.state.commandTypeInput) ? this.state.commandTypeInput : this.state.supportedCommands.at(0);
        const commandPayload = this.fieldIsNotEmpty(this.state.commandPayloadInput) ? this.state.commandPayloadInput : "{}";
        const commandDevices = this.state.commandDeviceInput;

        let commandPayloadJson;

        try {
            commandPayloadJson = JSON.parse(commandPayload);
        } catch (e) {
            commandPayloadJson = commandPayload;
        }

        let payload = {
            "type": commandType,
            "payload": commandPayloadJson,
            "deviceIds": commandDevices
        };

        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/commands'), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(payload),
            })
                .then((data) => {
                    if (data.ok) {
                        return data.json();
                    }

                    this.onFormSendingError();
                    throw new Error('Something went wrong');
                })
                .then((data) => this.processServerResponse(data));
        })
    }

    onFormSendingError() {
        this.setState({formError: 'Problem during communication with the server.'});
    }

    processServerResponse(jsonPayload) {
        this.onCloseModal();
        this.setState({
            formError: false,
            shouldRedirect: true,
            shouldRedirectToBatchId: jsonPayload.batchId
        });
    }
}

export default CommandSenderForm;
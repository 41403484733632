import React from 'react';
import {Auth} from "@aws-amplify/auth";
import Config from "../../config/config";
import Preloader from "../Preloader";

class VersionStamperRepositorySelectorForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            selectedRepository: undefined,
            submitInProgress: false,
            images: [],
            imageDigest: undefined
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleVersionInputChange = this.handleVersionInputChange.bind(this);
    }

    handleInputChange(event) {
        const repositoryName = event.target.value;

        this.setState({"selectedRepository": repositoryName});

        this.submit(repositoryName);
    }

    handleVersionInputChange(event) {
        const version = event.target.value;

        this.setState({"version": version});
    }

    render() {
        return <div>
            <div className="row m-0 p-0">
                <div className="col-md-4"/>
                <div className="col-md-4">
                    {this.props.repositories !== undefined ?
                        <form id="selectRepository">
                            <div className="form-group">
                                <label htmlFor="selectRepositoryInput">Select application</label>
                                <select
                                    className="form-control"
                                    id="selectRepositoryInput"
                                    onLoadedData={this.handleInputChange}
                                    onChange={this.handleInputChange}>
                                    <option value="">Select application</option>
                                    {this.props.repositories.map((repository) => {
                                        return <option
                                            key={repository}>{repository}</option>
                                    })}
                                </select>
                            </div>
                        </form>
                        :
                        ''
                    }

                    {this.state.submitInProgress ? <Preloader/> : ''}

                    {this.state.selectedRepository !== undefined ? <div>
                        Available images for: <strong>{this.state.selectedRepository}</strong>:

                        <div className="mt-3 ml-5 mb-3">
                            {this.state.images.map((image) => {
                                return <button key={image.digest}
                                               type="button"
                                               className="btn btn-block btn-primary btn-sm"
                                               data-toggle="modal"
                                               data-target="#stamp-version-modal"
                                               onClick={() => this.stampVersion(image.digest)}>
                                    Stamp version <strong>{image.tags.join(', ')}</strong>
                                </button>
                            })}
                        </div>
                    </div> : ''}

                    {this.state.imageDigest !== undefined ?
                        <div>
                            <div className="modal fade" id="stamp-version-modal">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">Stamp Version</h4>
                                            <button type="button"
                                                    id="closeModalButton"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => this.onCloseModal()}>
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div>
                                                <div className="container-fluid">
                                                    <section className="content">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="text-xs">for image digest {this.state.imageDigest}</div>
                                                                <form id="stampVersionForm">
                                                                    <div className="form-group">
                                                                        <label>New Version</label>
                                                                        <input id="versionInput"
                                                                               className="form-control"
                                                                               onChange={this.handleVersionInputChange}></input>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn ssBackground btn-block"
                                                    onClick={() => this.submitStampVersionRequest()}>Stamp version
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ''}
                </div>
            </div>
            <div className="col-md-4"/>
        </div>
    }

    submit(repositoryName) {

        if (repositoryName === "") {
            return;
        }

        this.setState({"submitInProgress": true});

        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/version-stamper/repositories/' + repositoryName + "/images"), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
                .then((data) => {
                    this.setState({"submitInProgress": false});

                    if (data.ok) {
                        return data.json();
                    }

                    throw new Error('Something went wrong');
                })
                .then((data) => this.processServerResponse(data));
        })
    }

    processServerResponse(jsonPayload) {
        this.setState({"images": jsonPayload});
    }

    stampVersion(imageDigest) {
        this.setState({"imageDigest": imageDigest});
        console.log('stamping version: ' + imageDigest + ' from: ' + this.state.selectedRepository);
    }

    onCloseModal() {
        this.setState({"version": undefined});
        document.querySelector('#closeModalButton').click();
    }

    submitStampVersionRequest() {
        this.setState({"submitInProgress": true});

        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            const payload = {
                "imageDigest": this.state.imageDigest,
                "version": this.state.version
            }

            fetch(Config.apiPath('/version-stamper/repositories/' + this.state.selectedRepository + "/images"), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(payload),
            })
                .then((data) => {
                    this.setState({"submitInProgress": false});

                    if (data.ok) {
                        return data;
                    }

                    throw new Error('Something went wrong');
                })
                .then(() => {
                    this.onCloseModal();
                    document.querySelector('#stampVersionForm').reset();
                    this.submit(this.state.selectedRepository);
                });
        })
    }

}

export default VersionStamperRepositorySelectorForm;
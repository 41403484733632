export const EXPLANATIONS = [
    {
        id: "NO_EXPLANATION",
        title: "Brak wyjaśnienia"
    },
    {
        id: "SECURITY_MONITORING_DISABLED",
        title: "Brak monitoringu"
    },
    {
        id: "ITEMS_STOLEN",
        title: "Kradzież"
    },
    {
        id: "ITEMS_RETURNED",
        title: "Zwrócono przy kasie"
    },
    {
        id: "CASHIER_MISTAKE",
        title: "Błąd kasjera"
    },
    {
        id: "EMPLOYEE_MISTAKE",
        title: "Błąd pracownika stoiska"
    },
    {
        id: "DUPLICATE_PRINTOUT_ON_SCALES",
        title: "Duplikat wydruku z wagi"
    },
    {
        id: "TO_EXPLAIN_WITH_CUSTOMER",
        title: "Do wyjaśnienia z klientem"
    },
    {
        id: "ILLEGIBLE_SCALES_CODE",
        title: "Towar zważony przy kasie"
    },
    {
        id: "CUSTOMER_CHANGED_HIS_MIND",
        title: "Zmiana decyzji klienta"
    },
    {
        id: "ITEMS_ABANDONED",
        title: "Towar porzucony na sklepie"
    },
    {
        id: "SYSTEM_FAULT",
        title: "Błąd systemu"
    }];
const {REACT_APP_API_URL} = process.env;

class Config {

    static apiEndpoint() {
        return REACT_APP_API_URL;
    }

    static apiPath(path) {
        return this.apiEndpoint() + path;
    }
}

export default Config;
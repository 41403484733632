import React from 'react';
import ContentHeader from "../ContentHeader";
import Config from "../../config/config";
import {Auth} from "@aws-amplify/auth";
import SingleDeviceInfo from "./SingleDeviceInfo";

class OrganizationView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {user: {}};
    }

    componentDidMount() {
        this.fetchOrganization();
    }

    fetchOrganization() {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/organizations/'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(organization => this.mapOrganizationToDevices(organization))
                .then(data => this.setState({devices: data}))
        });
    }

    mapOrganizationToDevices(restResponse) {
        const devices = [];

        for (const entrepreneur of restResponse) {
            for (const company of entrepreneur['companies']) {
                for (const store of company['stores']) {
                    for (const device of store['devices']) {
                        devices.push(
                            {
                                "entrepreneur": entrepreneur.name,
                                "company": company.name,
                                "store": store.name,
                                "device": device.name,
                                "deviceId": device.id,
                                "stand": null
                            }
                        );
                    }
                }
            }
        }

        return devices;
    }

    render() {
        return <div>
            <ContentHeader title="Organization"/>
            <section className="content">

                {this.state.devices !== undefined ?
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                <tr>
                                                    <th>No.</th>
                                                    <th>Entrepreneur</th>
                                                    <th>Company</th>
                                                    <th>Store</th>
                                                    <th>Device</th>
                                                    <th>Device Id</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.devices.map((device, index) => {
                                                    return <SingleDeviceInfo key={device['deviceId']}
                                                                             num={index + 1}
                                                                             data={device}
                                                    />
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
            </section>
        </div>
    }
}

export default OrganizationView;
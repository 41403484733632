import React from 'react';

class Footer extends React.Component {

    render() {
        return <footer className="main-footer">
            <strong>Copyright &copy; {new Date().getFullYear()} <a href="https://simplestep.pl">SimpleStep.pl</a></strong>. All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> 1.1.0
            </div>
        </footer>;
    }
}

export default Footer;
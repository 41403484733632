import React from 'react';

class DeviceDiskUsage extends React.Component {
    kilobytesToGigabytes(valueInKb) {
        return (valueInKb / 1024 / 1024).toFixed(2);
    }

    totalSize() {
        return this.props.usage.available + this.props.usage.used;
    }

    showProgressBar(percentage) {
        let color = "bg-success";

        if (percentage > 65) {
            color = "bg-warning";
        }

        if (percentage > 85) {
            color = "bg-danger";
        }

        return <div className="progress">
            <div className={"progress-bar " + color}
                 role="progressbar"
                 style={{"width": percentage + "%"}}
                 aria-valuenow={percentage}
                 aria-valuemin="0"
                 aria-valuemax="100"
                 title={this.props.desc}>{percentage}% ({this.props.name})
            </div>
        </div>
    }

    render() {
        return <div>
            {this.kilobytesToGigabytes(this.props.usage.used)} GB / {this.kilobytesToGigabytes(this.totalSize())} GB
            {this.showProgressBar(this.props.usage.percentUsed)}
        </div>
    }
}

export default DeviceDiskUsage;
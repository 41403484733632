import React from 'react';

class DeviceType extends React.Component {
    getImage(deviceType) {
        if (deviceType === "zymbit_v2") {
            return "zymbit.jpeg";
        } else if (deviceType === "swissbit_v1") {
            return "swissbit.png";
        } else {
            return "unknown.png";
        }
    }

    render() {
        const physicalDeviceState = this.props.physicalDeviceState;

        if (physicalDeviceState === undefined
            || physicalDeviceState === null
            || physicalDeviceState.deviceStatus === undefined
            || physicalDeviceState.deviceStatus === null
            || physicalDeviceState.deviceStatus.deviceType === undefined
            || physicalDeviceState.deviceStatus.deviceType === null) {
            return "?"
        }

        const deviceType = physicalDeviceState.deviceStatus.deviceType;

        return <span><img alt="Device Logo" width="40" src={"img/" + this.getImage(deviceType)} title={deviceType}></img></span>;
    }
}

export default DeviceType;